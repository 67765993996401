import dayjs from "dayjs"

import createBiest from "./createBiest"

export default function populateDatabase(setList) {

    let today = dayjs()
    // adding 10 farms
    for (let farm = 0; farm < 5; farm++) {
        // adding 100 samples per farm
        for (let sample = 0; sample < 10; sample++) {

            let biest_brix = Math.floor(Math.random() * (30 - 11 + 1) + 11)
            let biest_igg = biest_brix > 18 ? Math.round(Number(biest_brix) * 11.582 - 208.05) : 0
            let bloed_igg = Math.floor(Math.random() * 31)
            let random_day = Math.floor(Math.random() * 31)
            let birthDate = today.subtract(random_day, 'day')
            let biest = {
                boerNaam: `boer_${farm}`,
                boerEmail: `email_${farm}`,
                bedrijfId:"",
                bedrijfAdres: "",
                moederLeeftijd: "ouder",
                kalfGeslacht: "",
                biestTijdstip: "",
                bloedBrixWaarde: 0,
                moederId: `id_${(farm+1) * 1000 + sample}`,
                kalfId: `id_${(farm+1) * 2000 + sample}`,
                kalfDatum: birthDate.format('YYYY-MM-DD'),
                biestVolume: `${Math.floor(Math.random() * (40 - 10 + 1) + 10) / 10}`,
                biestBrixWaarde: biest_brix,
                biestIgGWaarde: biest_igg,
                bloedIgGWaarde: bloed_igg,
                toelichting: "",
            }
            createBiest(setList, biest)
        }
    }
}