import React from "react"
import { Paper, Typography, Divider, Box } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function Manager({ }) {
   
    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
      }
      
      const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
      ];
   
    return (
        <>
            <Typography variant="h6">overzicht</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 128,
                        height: 128,
                    },
                }}
            >
                <Paper>
                    <Typography variant="h3">16</Typography>
                    <Typography variant="subtitle1"># koeien</Typography>
                </Paper>
                <Paper>
                    <Typography variant="h3">153</Typography>
                    <Typography variant="subtitle1"># bieststalen</Typography>
                </Paper>
                <Paper>
                    <Typography variant="h3">5.6l</Typography>
                    <Typography variant="subtitle1">volume melk (mediaan)</Typography>
                </Paper>
                <Paper>
                    <Typography variant="h3">28%</Typography>
                    <Typography variant="subtitle1">Brix (mediaan)</Typography>
                </Paper>
                <Paper>
                    <Typography variant="h3">4.8l</Typography>
                    <Typography variant="subtitle1">volume melk (mediaan)</Typography>
                </Paper>
                <Paper>
                    <Typography variant="h3">24%</Typography>
                    <Typography variant="subtitle1">Brix (mediaan)</Typography>
                </Paper>
            </Box>
            <Divider />
            <Typography variant="h6">klantenlijst</Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Dessert (100g serving)</TableCell>
                            <TableCell align="right">Calories</TableCell>
                            <TableCell align="right">Fat&nbsp;(g)</TableCell>
                            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                            <TableCell align="right">Protein&nbsp;(g)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.calories}</TableCell>
                                <TableCell align="right">{row.fat}</TableCell>
                                <TableCell align="right">{row.carbs}</TableCell>
                                <TableCell align="right">{row.protein}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>

    )
}