import React from "react"
import { Amplify } from "aws-amplify"
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import { WaterDrop as Milk, Timeline as Benchmark} from '@mui/icons-material'
import NavigatorCCIcon from "./NavigatorCCIcon"

export default function Navigator({ screenSelect, setScreenSelect }) {
    
    const admin = Amplify.Auth.user.attributes['custom:type'] === 'admin'

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={screenSelect}
                onChange={(event, newValue) => {setScreenSelect(newValue);}}
            >
                <BottomNavigationAction label="Biest" icon={<Milk />} />
                <BottomNavigationAction label="Koe" icon={<NavigatorCCIcon/>} />
                {admin &&<BottomNavigationAction label="Kalf" icon={<NavigatorCCIcon/>} />}
                {admin && <BottomNavigationAction label="Bedrijf" icon={<Benchmark />} />}
            </BottomNavigation>
        </Paper>
    )
}