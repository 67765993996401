import React from "react"
import { Box } from '@mui/material'

import BedrijfPlot from "./bedrijfComponents/BedrijfPlot"
import KoeBackground from "./koeComponents/KoeBackground"

export default function Bedrijf( {biestList} ){
    return(
        <Box
        sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
                m: '10px',
                width: '100%',
                height: window.innerHeight - 170,
            },
        }}
    >
            <BedrijfPlot biestList= {biestList} />
            <KoeBackground />
        </Box>
    )
}