import { API } from "aws-amplify"
import { listBiests } from "../graphql/queries"

export default async function fetchBiestList(setData){
    const apiData = await API.graphql({ 
        query: listBiests,
        variables:{ limit:1000 }
    })
    const biestFromAPI = apiData.data.listBiests.items;
    setData(biestFromAPI)
}
