import { API } from "aws-amplify"
import { updateBiest as updateBiestMutation } from "../graphql/mutations"

import fetchBiestList from "./fetchBiestList"

export default async function modifyBiest(setData, biest){
    delete biest["updatedAt"]
    delete biest["createdAt"]
    delete biest["__typename"]
    await API.graphql({
        query: updateBiestMutation,
        variables: { input: biest },
    });
    fetchBiestList(setData)
}