/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://2wgyajbpxrbpjdo6uz23tuif5u.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lzfvhdkgmvbqrerksk75llwkqi",
    "aws_cognito_identity_pool_id": "eu-west-1:7c654c9a-3adb-4dd0-a8e8-ec23273247ab",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_RjPZb6I64",
    "aws_user_pools_web_client_id": "5q5gljbce6fplpff623ubrif6h",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
