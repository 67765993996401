import React from "react";
import Plot from 'react-plotly.js';

export default function KalfTaartDiagram({data}) {
    return (
        <Plot
            style={{ position: 'fixed', left: 5, zIndex: 4000 }}
            data={[{
                values: data,
                labels: [
                    'goed', 'onvoldoende biestopname', 'onvoldoende biestkwaliteit', 'opname goed, kwaliteit onvoldoende'],
                type: 'pie',
                marker: { colors: ['#F4433688', '#4caf5066', '#F4433655', '#ff980066'] },
            }]}
            config={{
                displayModeBar: false,
            }}
            layout={{
                width: window.innerWidth - 25,
                height: (window.innerHeight - 175),
                title: '<b>Biestopname kalveren</b>',
                titlefont: { size: 15 },
                margin: { l: 40, b: 40, t: 40, r: 40 },
                showlegend: false,
            }}
        />
    )
}