import React from "react"
import Plot from 'react-plotly.js'

export default function KalfBoxPlot({ data }) {
    
    return (
        <Plot
            style={{ position: 'fixed', left: 5, zIndex: 4000 }}
            data={[
                {
                    x: data.map((v) => v.bloedIgGWaarde),
                    y: data.map((v) => v.biestIgGWaarde),
                    hovertemplate:
                        '<i>Kalfnummer</i>: %{text}' +
                        '<br><i>Biest IgG</i>: %{y} g/l' +
                        '<br><i>Bloed IgG</i>: %{x} g/l' +
                        '<extra></extra>',
                    text: data.map((v) => v.kalfId),
                    type: 'scatter',
                    mode: 'markers',
                    marker: { color: '#2196f3' },
                },
            ]}
            config={{
                displayModeBar: false,
            }}
            layout={{
                width: window.innerWidth - 25,
                height: (window.innerHeight - 175),
                title: '<b>Biestopname kalveren</b>',
                titlefont: { size: 15 },
                xaxis: {
                    title: '<b>bloed IgG [g/l]</b>',
                    titlefont: { size: 12 },
                    range: [-4.99, 39.99],
                    type: 'linear',
                    gridwidth: 1,
                    gridcolor: '#bdbdbd',
                    zeroline: false,
                    fixedrange: true,
                },
                yaxis: {
                    title: '<b>biest IgG [g/l]</b>',
                    titlefont: { size: 12 },
                    range: [-9.99, 149.99],
                    type: 'linear',
                    gridwidth: 1,
                    gridcolor: '#bdbdbd',
                    zeroline: false,
                    fixedrange: true,
                },
                margin: { l: 40, b: 50, t: 40, r: 0 },
                plot_bgcolor: 'rgba(0,0,0,0)',
                paper_bgcolor: 'rgba(0,0,0,0)',
                showlegend: false,
            }}
        />
    )
}