import React from "react"
import { SvgIcon } from "@mui/material"

export default function NavigatorCCIcon() {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="currentColor"
                fill="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m12.08,20.21c-2.23-.07-3.51-.44-4.21-1.2-.76-.83-.71-1.91-.64-3.16.03-.54.06-1.09.03-1.69-.05-1.14-.33-2.12-.88-3.08-.56-.98-.87-2.06-.61-3.82-.33-.03-.68-.04-1.07-.04-.02,0-.04,0-.06,0C2.59,7.22.2,6.24.03,3.46l-.03-.57.56-.1c.46-.08,4.58-.75,6.21.84.38.37.8,1.04.63,2.11l-.02.08c-.04.13-.07.26-.11.38,2.73.68,3.39,2.54,3.41,4.15.01,1.16-.46,2.1-.95,3.1-.49.99-1.04,2.11-1.26,3.68.02.45.11.77.33,1,.44.47,1.51.73,3.27.79,1.65-.06,2.68-.33,3.12-.82.21-.23.3-.56.33-.95-.32-1.39-.88-2.3-1.43-3.19-.66-1.07-1.29-2.09-1.2-3.63.08-1.36.6-2.49,1.49-3.26.61-.53,1.39-.88,2.32-1.06-.02-.06-.04-.12-.06-.19v-.05c-.24-1.06.16-1.73.53-2.11,1.7-1.73,6.25-.88,6.29-.87l.47.09.04.48c0,.05.11,1.33-.85,2.41-.8.91-2.08,1.39-3.79,1.43h-.04s-.04,0-.04,0c-.35-.03-.68-.05-.99-.05.37,2.13-.17,3.05-.74,4.01-.06.1-.12.2-.18.31-.46.8-.67,1.66-.68,2.71,0,.5.03.98.07,1.45.09,1.26.18,2.45-.61,3.34-.7.78-1.96,1.17-4.07,1.25Zm4.94-12.95c-.74.14-1.34.4-1.8.79-.64.55-.99,1.34-1.05,2.37-.07,1.14.39,1.87,1.01,2.88.08.13.16.26.25.4.06-1.06.34-2.01.83-2.87.06-.11.13-.22.19-.33.49-.82.89-1.5.56-3.25Zm-10,.2c-.23,1.56.1,2.33.46,2.98.5.87.81,1.79.96,2.76.05-.1.1-.21.15-.31.46-.93.83-1.67.82-2.52,0-.63-.02-2.28-2.39-2.91Zm-2.21-1.53c.43,0,.83.02,1.21.06.04-.16.08-.32.13-.48.08-.55-.11-.81-.27-.96-.77-.75-2.94-.77-4.48-.59.47,1.84,2.57,1.99,3.27,1.97.05,0,.09,0,.14,0Zm13.5-.06c.34,0,.7.02,1.07.05,1.31-.04,2.26-.37,2.81-.98.3-.33.44-.7.5-.98-1.59-.21-3.83-.16-4.58.6-.14.14-.32.4-.21.93.04.13.08.27.11.39.1,0,.2,0,.3,0Z"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.24,3.06l-.26-.12c-1.39-.65-2.14-1.62-2.52-2.33L6.58,0c.27.49.78,1.16,1.7,1.66.54-.2,1.78-.57,3.42-.62,1.38-.04,2.74.17,4.03.62.4-.22,1.24-.78,1.73-1.66l1.12.61c-.85,1.56-2.47,2.3-2.54,2.33l-.24.11-.25-.09c-1.22-.46-2.51-.67-3.82-.64-1.92.05-3.22.62-3.23.62l-.27.12Z"
                />
            </svg>
        </SvgIcon>
    )
}