import { API } from "aws-amplify"
import { createBiest as createBiestMutation } from "../graphql/mutations"
import fetchBiestList from "./fetchBiestList"

export default async function createBiest(setData, biest){
    await API.graphql({
        query: createBiestMutation,
        variables: { input: biest },
    });
    fetchBiestList(setData)
}