import React, { useState } from "react"
import { Box, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"

export default function BiestInfo({ newSample, setNewSample }) {

    
    function handleChange(event) {
        event.target.value = event.target.value.replace(/,/g, '.')        
        setNewSample(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }
    let wrongVolume = false
    let wrongVolumeText = ''
    let wrongBrix = false
    let wrongBrixText = ''
    if(newSample.biestVolume < 0){
        wrongVolume = true
        wrongVolumeText = "onrealistisch melkvolume"
    }
    if(newSample.biestVolume*10 % 1 != 0){
        wrongVolume = true
        wrongVolumeText = "rond af naar één cijfer na de komma"
    }
    if(newSample.biestBrixWaarde*100 % 1 != 0){
        wrongBrix = true
        wrongBrixText = "rond af naar één cijfer na de komma"
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" >
                <TextField
                    error={wrongVolume}
                    id="biestVolume"
                    label="volume verzameld (L)"
                    name="biestVolume"
                    variant="standard"
                    fullWidth
                    value={newSample.biestVolume}
                    type="text"
                    inputProps={{ step: 0.1, inputMode: 'number', }}
                    sx={{mr: '30px'}}
                    margin="normal"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true, }}
                    helperText={wrongVolume && wrongVolumeText}
                />

                <TextField
                    error={wrongBrix}
                    id="biestBrixWaarde"
                    label="Brix (%)"
                    name="biestBrixWaarde"
                    type="text"
                    variant="standard"
                    fullWidth
                    value={newSample.biestBrixWaarde}
                    inputProps={{ step: 0.1, inputMode: 'decimal', }}
                    margin="normal"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true, }}
                    helperText={wrongBrix && wrongBrixText}
                />
            </Box>

            <br></br>

            <Typography sx={{ color: 'gray' }} variant='caption'>tijdstip biestgift na geboorte</Typography>

            <ToggleButtonGroup exclusive fullWidth color="primary" value={newSample.biestTijdstip} onChange={handleChange}>
                <ToggleButton size='small' name='biestTijdstip' value='<2u'>{'<2u'}</ToggleButton>
                <ToggleButton size='small' name='biestTijdstip' value='2-6u'>{'2-6u'}</ToggleButton>
                <ToggleButton size='small' name='biestTijdstip' value='>6u'>{'>6u'}</ToggleButton>
            </ToggleButtonGroup>
        </>
    )
}