import React, { useState } from "react"
import { Drawer, Button, Table, TableRow, TableBody, TableCell, TableContainer, TableHead, Paper, Typography, Toolbar, IconButton } from '@mui/material'
import { Info as InfoIcon, Close as CloseIcon } from '@mui/icons-material'

import KoeInfoCell from './KoeInfoCell'

export default function KoeInfo({ data, setBiestList }) {
    const [open, setOpen] = useState(false)

    function average(list) {
        let sum = 0
        list.forEach((element) => sum += Number(element))
        let average = sum / list.length
        return (average)
    }

    const summary = [
        { name: '# bieststalen', value: data.length },
        { name: '<22% Brix', value: `${data.filter((sample) => sample.biestBrixWaarde < 22).length} (${Math.round(data.filter((sample) => sample.biestBrixWaarde < 22).length/data.map((sample)=>sample.biestBrixWaarde).length*10000)/100}%)` },
        { name: '>6u na geboorte', value: `${data.filter((sample) => sample.biestTijdstip === '>6u').length} (${Math.round(data.filter((sample) => sample.biestTijdstip === '>6u').length/data.map((sample)=>sample.biestTijdstip).length*10000)/100}%)` },
        { name: 'gem. Brix %', value: `${Math.round(average(data.map((sample) => sample.biestBrixWaarde))*100)/100}%` },
        { name: 'gem. volume', value: `${Math.round(average(data.map((sample) => sample.biestVolume))*100)/100} l` },
        { name: 'min. Brix %', value: `${Math.min(...data.map((sample) => sample.biestBrixWaarde))}%` },
        { name: 'max. Brix %', value: `${Math.max(...data.map((sample) => sample.biestBrixWaarde))}%` },
    ]
    
    return (
        <>
            <Button
                size='small'
                endIcon={<InfoIcon />}
                variant="contained"
                sx={{ position: 'fixed', left: 10, bottom: 65, zIndex: 1000 }}
                onClick={() => setOpen(true)}
            >
                Toon Info
            </Button>
            <Drawer anchor="bottom" onClose={() => setOpen(false)} open={open} >
                <Toolbar display='flex' justify="space-between">
                    <Typography variant="button" sx={{ flexGrow: 1 }}>
                        Info biestkwaliteit koeien
                    </Typography>
                    <IconButton sx={{ color: 'black' }} onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>

                <Typography sx={{ ml: 2 }} variant="subtitle2">
                    Samenvatting
                </Typography>
                <TableContainer sx={{ pl: 1 }}>
                    <Table size="small">
                        <TableBody>
                            {summary.map((item) =>
                                <TableRow key={item.name}>
                                    <TableCell sx={{ width: '10px' }} />
                                    <TableCell sx={{ fontSize: '12px' }} component="th" scope="row">{item.name}</TableCell>
                                    <TableCell sx={{ fontSize: '12px' }} align="right">{item.value}</TableCell>
                                    <TableCell sx={{ width: '10px' }} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography sx={{ mt: 2, ml: 2 }} variant="subtitle2">
                    Bieststalen aanpassen of verwijderen
                </Typography>
                <TableContainer component={Paper} sx={{ pl: 1, maxHeight: 300 }}>
                    <Table stickyHeader sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '10px' }} />
                                <TableCell sx={{ fontSize: '12px' }} > datum</TableCell>
                                <TableCell sx={{ fontSize: '12px' }} align="right" >biest Brix&nbsp;[%]  </TableCell>
                                {/* <TableCell align="right" sx={{ position: 'sticky', right: 0, background: 'white', width: '40px', fontSize: '12px' }} /> */}
                                <TableCell sx={{ width: '10px' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((biestSample) =>
                                <KoeInfoCell key={biestSample.id} biestSample={biestSample} setBiestList={setBiestList} />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Drawer>
        </>
    )
} 