import React from "react"
import { Typography, Paper } from "@mui/material"
import colorOfGradient from "../../../../functions/colorOfGradient"

export default function BiestResult({ newSample }) {

    const x = Math.round(200/(11.582*newSample.biestBrixWaarde-208.05)*10)/10
    const x2 = Math.round(220/(11.582*newSample.biestBrixWaarde-208.05)*10)/10
    const y = Math.round(4*(11.582*newSample.biestBrixWaarde-208.05))
    const igg = Math.round(11.582*newSample.biestBrixWaarde-208.05)
    const x3 = (Math.round(300/(11.582*newSample.biestBrixWaarde-208.05)*10)/10)

   
    const advies = [
        "De Brix-waarde moet tussen 0 en 100% liggen. Gelieve een nieuwe meting te doen.",
        "Deze Brix-waarde is onverwacht laag. Voer de meting opnieuw uit.",
        `Deze biest is van slechte kwaliteit en bevat onvoldoende antistoffen voor een pasgeboren kalf.`,
        `Deze biest is van slechte kwaliteit en bevat onvoldoende antistoffen voor een pasgeboren kalf (= ${igg} g IgG per liter).`,        
        `Deze biest is van minder goede kwaliteit en bevat onvoldoende antistoffen voor een pasgeboren kalf (= ${igg} g IgG per liter).`,
        `Deze biest is van goede kwaliteit en bevat voldoende antistoffen voor een pasgeboren kalf (${igg} g IgG per liter).`,
        `Deze biest is van zeer goede kwaliteit en bevat voldoende antistoffen voor een pasgeboren kalf (${igg} g IgG per liter).`,
        "Deze Brix-waarde is onverwacht hoog. Voer de meting opnieuw uit.",
        "De Brix-waarde moet tussen 0 en 100% liggen. Gelieve een nieuwe meting te doen."
    ]

    let textToDisplay = ""
    if (newSample.biestBrixWaarde < 0) {
        textToDisplay = advies[0]
    } else if (newSample.biestBrixWaarde < 10.0) {
        textToDisplay = advies[1]
    } else if (newSample.biestBrixWaarde < 18.1) {
        textToDisplay = advies[2] 
    } else if (newSample.biestBrixWaarde < 20.0) {
        textToDisplay = advies[3]
    } else if (newSample.biestBrixWaarde < 22.3) {
        textToDisplay = advies[4]        
    } else if (newSample.biestBrixWaarde < 25.0) {
        textToDisplay = advies[5] 
    } else if (newSample.biestBrixWaarde < 40.0) {
        textToDisplay = advies[6] 
    } else if (newSample.biestBrixWaarde < 100.1) {
        textToDisplay = advies[7]
    } else {
        textToDisplay = advies[8]
    }



    //     if(!newSample.biestVolume || !newSample.biestGedronken){
    //         textToDisplay = advies[5]
    //     } else if (newSample.biestVolume < x3){
    //         textToDisplay = advies[6]
    //     } else {
    //         textToDisplay = advies[7]            
    //     }
    // } else if (newSample.biestBrixWaarde < 100.1){
    //     if(!newSample.biestVolume || !newSample.biestGedronken){
    //         textToDisplay = advies[5]
    // }

    //     if (newSample.biestVolume<x){
    //         textToDisplay = advies[3]
    //     } else {
    //         textToDisplay = advies[4]
    //     }
    // } else if (newSample.biestBrixWaarde < 25.0) {
    //     textToDisplay = advies[6]
    // } else if (newSample.biestBrixWaarde < 45.1) {
    //     textToDisplay = advies[7]
    // } else if (newSample.biestBrixWaarde < 100.1) {
    //     textToDisplay = advies[8]
    // } else {
    //     textToDisplay = advies[9]


    let position = 0
    if (newSample.biestBrixWaarde > 10 && newSample.biestBrixWaarde < 45) {
        position += 2 + Math.round((newSample.biestBrixWaarde - 10) * 2.73)
    }

    console.log(x3)
    return (
        <>

            <Paper
                sx={{
                    width: "100%",
                    height: "138px",
                    borderRadius: '10px',
                    padding: '9px',
                    borderColor: colorOfGradient(position),
                    borderStyle: 'solid',
                    borderWidth: 2,
                }}
            >
                <Typography align='justify' variant='body2' sx={{ fontSize:'12px' }}>{textToDisplay} </Typography>

            </Paper>
            <Paper
                sx={{
                    position: 'relative',
                    left: `${position}%`,
                    borderRadius: '0px',
                    width: "2px",
                    height: "10px",
                    background: colorOfGradient(position),
                    opacity: position > 0 ? '100%' : '0%',


                }}
            />
            <Paper
                sx={{
                    width: "100%",
                    height: "10px",
                    borderRadius: '30px',
                    background: "linear-gradient(90deg, #F44336 29%, #E6E939 36%, #00A35E 43%)",
                    zIndex: 100000
                }}
            />


        </>
    )
}