import React from "react"
import Plot from 'react-plotly.js'

export default function KoePlot({ data, xRange }) {

    return (
        <Plot
            style={{ position: 'fixed', left: 5, zIndex: 4000 }}
            data={[
                {
                    x: data.map((v) => v.kalfDatum),
                    y: data.map((v) => v.biestBrixWaarde),
                    hovertemplate:
                        '<i>Koenummer</i>: %{text}' +
                        '<br><i>Brixwaarde</i>: %{y}%' +
                        '<extra></extra>',
                    text: data.map((v) => v.moederId),
                    type: 'scatter',
                    mode: 'markers',
                    marker: { color: '#2196f3' },
                },
            ]}
            config={{
                displayModeBar: false,
                responsive: true,
            }}
            layout={{
                width: window.innerWidth - 25,
                height: window.innerHeight - 175,
                title: '<b>Biestkwaliteit koeien</b>',
                titlefont: { size: 15 },
                xaxis: {
                    range: xRange,
                    type: 'date',
                    title: '<b>Periode</b>',
                    titlefont: { size: 12 },
                    showgrid: false,
                    zeroline: false,
                    showline: false,
                    tickangle: 30,
                },
                yaxis: {
                    range: [10.01, 34.99],
                    type: 'linear',
                    title: '<b>Biest Brix [%]</b>',
                    titlefont: { size: 12 },
                    gridwidth: 1,
                    gridcolor: '#bdbdbd',
                    fixedrange: true,
                },
                margin: { l: 40, b: 50, t: 40, r: 0 },
                plot_bgcolor: 'rgba(0,0,0,0)',
                paper_bgcolor: 'rgba(0,0,0,0)',
            }}
        />
    )
}