import React, { useState } from "react"
import { Drawer, Button, Table, TableRow, TableBody, TableCell, TableContainer, TableHead, Paper, Typography, Toolbar, IconButton } from '@mui/material'
import { Info as InfoIcon,  Close as CloseIcon } from '@mui/icons-material'


export default function KalfInfo({ data, setBiestList }) {
    const [open, setOpen] = useState(false)

    function average(list) {
        let sum = 0
        list.forEach((element) => sum += Number(element))
        let average = sum / list.length
        return (average)
    }

    const summary = [
        { name: '# bloedstalen', value: 22 },
        { name: 'Gem. IgG (g/l)', value: 17 },
        { name: 'Gem. leeftijd (dagen)', value: 4 },
        { name: 'Excellent IgG (< 25 g/l', value: `30%` },
        { name: 'Goed IgG (18.0 - 24.9 g/l)', value: `15%` },
        { name: 'Matig IgG (10.0 - 17.9 g/l)', value: `35%` },
        { name: 'Onvoldoende IgG (< 10.0 g/l)', value: `20%` },
    ]

    return (
        <>
            <Button
                size='small'
                endIcon={<InfoIcon />}
                variant="contained"
                sx={{ position: 'fixed', left: 10, bottom: 65, zIndex: 1000 }}
                onClick={() => setOpen(true)}
            >
                Toon Info
            </Button>
            <Drawer anchor="bottom" onClose={() => setOpen(false)} open={open}>

                <Toolbar display='flex' justify="space-between">
                    <Typography variant="button" sx={{ flexGrow: 1 }}>
                        Info biestopname koeien
                    </Typography>
                    <IconButton sx={{ color: 'black' }} onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>

                <Typography sx={{ ml: 2 }} variant="subtitle2">
                    Samenvatting
                </Typography>
                <TableContainer>
                <Table size="medium">
                        <TableBody>
                            {summary.map((item) =>
                                <TableRow key={item.name}>
                                    <TableCell sx={{ width: '10px' }} />
                                    <TableCell sx={{ fontSize: '12px' }} component="th" scope="row">{item.name}</TableCell>
                                    <TableCell sx={{ fontSize: '12px' }} align="right">{item.value}</TableCell>
                                    <TableCell sx={{ width: '10px' }} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Drawer>
        </>
    )
}