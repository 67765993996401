import React, { useState } from "react"
import { Button, Menu, MenuItem } from '@mui/material'
import { ZoomIn as ZoomInIcon } from '@mui/icons-material'
import dayjs from "dayjs"

export default function KoePeriod({ setPeriod }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const today = dayjs()
    function openMenu(event) {
        setAnchorEl(event.currentTarget)
    }
    function selectPeriod(event) {
        setAnchorEl(null)
        let x1 = today.subtract(event.target.value, event.target.title).format('YYYY-MM-DD')
        let x2 = today.add(1,'day').format('YYYY-MM-DD')
        setPeriod([x1, x2])
    }

    return (
        <>
            <Button
                size='small'
                startIcon={<ZoomInIcon />}
                variant="contained"
                sx={{ position: 'fixed', right: 10, bottom: 65, zIndex: 1000 }}
                id='zoom-button'
                aria-haspopup='true'
                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                onClick={openMenu}
            >
                Kies periode
            </Button >
            <Menu
                id="zoom-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={selectPeriod} title='day' value={7}>7 dagen</MenuItem>
                <MenuItem onClick={selectPeriod} title='month' value={1}>1 maand</MenuItem>
                <MenuItem onClick={selectPeriod} title='month' value={6}>6 maanden</MenuItem>
                <MenuItem onClick={selectPeriod} title='year' value={1}>1 jaar</MenuItem>
            </Menu>
        </>
    )
}