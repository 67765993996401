import React from "react"
import Plot from 'react-plotly.js'
import { Amplify } from "aws-amplify"


export default function BedrijfPlot({ biestList }) {

    const farms = [...new Set(biestList.map(biest => biest.boerNaam))]
    const data = farms.map((farm) => ({
        name: farm,
        y: biestList.filter((biest) => biest.boerNaam === farm).map((farmbiest) => farmbiest.biestBrixWaarde),
        type: 'box',
        ...(farm === "boer_0") ? { marker: { color: '#2196f3' } } : { marker: { color: 'gray' } },
    }))

    for (let i = 0; i < data.length; i++) {
        let y_sort = data[i].y.sort((a, b) => a - b)
        let y_m = data[i].y.length % 2 ? y_sort[Math.floor(data[i].y.length / 2)] : (y_sort[Math.floor(data[i].y.length / 2) - 1] + y_sort[Math.floor(data[i].y.length / 2)]) / 2
        data[i]['y_m'] = y_m
    }

    data.sort((a, b) => a.y_m - b.y_m)

    return (
            <Plot
                style={{ position: 'fixed', left: 5, zIndex: 4000 }}
                data={data}
                config={{
                    displayModeBar: false,
                    staticPlot: 'true',
                }}
                layout={{
                    width: window.innerWidth - 25,
                    height: window.innerHeight - 175,
                    title: '<b>Biestkwaliteit bedrijven</b>',
                    titlefont: { size: 15 },
                    xaxis: {
                        title:{
                            text: '<b>bedrijven</b>',
                            standoff: window.innerHeight,
                        },
                        titlefont: { size: 12 },
                        showgrid: false,
                        zeroline: false,
                        showline: false,
                        showticklabels: false,
                        fixedrange: true,
                    },
                    yaxis: {
                        range: [10.01, 39.99],
                        type: 'linear',
                        title: '<b>biest Brix [%]</b>',
                        titlefont: { size: 12 },
                        gridwidth: 1,
                        gridcolor: '#bdbdbd',
                        fixedrange: true,
                    },
                    margin: { l: 40, b: 50, t: 40, r: 0 },
                    plot_bgcolor: 'rgba(0,0,0,0)',
                    paper_bgcolor: 'rgba(0,0,0,0)',
                    showlegend: false,
                    annotations: [
                        {
                          x: 'boer_0',
                          y: 0,
                          yshift:-25,
                          xref: 'x',
                          yref: 'paper',
                          text: `<b>${Amplify.Auth.user.attributes.name}</b>`,
                          font:{color: '#2196f3'},
                          showarrow: false,
                        }
                      ]
                }}
            />
    )
}