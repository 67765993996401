import React, { useState, useEffect } from "react"
import dayjs from "dayjs"
import { Amplify } from 'aws-amplify'
import { Dialog, Container, Box, Button, Stepper, Step, StepLabel } from "@mui/material"

import BiestInfo from "./BiestInfo"
import KoeInfo from "./KoeInfo"
import KalfInfo from "./KalfInfo"
import BiestResult from "./BiestResult"
import createBiest from '../../../../functions/createBiest'
import modifyBiest from '../../../../functions/modifyBiest'

export default function AddSampleDialog({ open, setOpen, setBiestList, sample }) {


    const initSample = {
        boerNaam: Amplify.Auth.user.attributes.name,
        boerEmail: Amplify.Auth.user.attributes.email,
        bedrijfId: "",
        boerderijNaam: "",
        bedrijfAdres: "",
        moederId: "",
        moederLeeftijd: "",
        kalfId: "",
        kalfDatum: dayjs(),
        kalfGeslacht: "",
        biestTijdstip: "",
        biestVolume: "",
        biestGedronken: "",
        biestBrixWaarde: "",
        biestIgGWaarde: 0,
        biestGedronkenTijdstip: "",
        bloedBrixWaarde: 0,
        bloedIgGWaarde: 0,
        toelichting: "",
    }


    const [step, setStep] = useState(0)
    const [newSample, setNewSample] = useState(initSample)

    useEffect(() => {
        if (sample) {
            setNewSample(sample)
        }
    }, [sample])

    function prepareSample() {
        setNewSample((prevState) => (
            {
                ...prevState,
                biestBrixWaarde: Number(prevState.biestBrixWaarde),
                biestIgGWaarde: Math.round(Number(prevState.biestBrixWaarde) * 11.582 - 208.05),
                ...(typeof prevState.kalfDatum === 'object') && { kalfDatum: prevState.kalfDatum.format('YYYY-MM-DD') },
            }))
        setStep((prevState) => (prevState + 1))
    }
    function sendSample() {
        if (newSample.id) {
            modifyBiest(setBiestList, newSample)
        } else {
            createBiest(setBiestList, newSample)
        }
        setOpen(false)
        setStep(0)
        setNewSample(initSample)
    }

    function cancelSample() {
        setOpen(false)
        setStep(0)
        setNewSample(initSample)
    }


    let biestEmpty = true
    if (newSample.biestBrixWaarde) {
        biestEmpty = false
    }

    console.log(newSample)
    return (
        <Dialog fullWidth  open={open}>
            <Container sx={{ marginBottom: '10px' }}>
                <br />
                <Stepper activeStep={step} alternativeLabel>
                    <Step>
                        <StepLabel>koe</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>biest</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>kalf</StepLabel>
                    </Step>
                </Stepper>
                <br />
                {step === 0 &&
                    <>
                        <KoeInfo newSample={newSample} setNewSample={setNewSample} />
                        <br />
                        <br />
                        <Box display="flex" justifyContent="space-between" >
                            <Button onClick={cancelSample}>annuleer</Button>
                            <Button onClick={() => setStep((prevState) => (prevState + 1))}>volgende</Button>
                        </Box>
                    </>
                }
                 {step === 1 &&
                    <>
                        <BiestInfo newSample={newSample} setNewSample={setNewSample} />
                        <br />
                        <br />
                        <Box display="flex" justifyContent="space-between" >
                            <Button onClick={() => setStep((prevState) => (prevState - 1))}>vorige</Button>
                            <Button disabled={biestEmpty} onClick={prepareSample}>volgende</Button>
                        </Box>
                    </>
                }
                {step === 2 &&
                    <>
                        <KalfInfo newSample={newSample} setNewSample={setNewSample} />
                        <br />
                        <Box display="flex" justifyContent="space-between" >
                            <Button onClick={() => setStep((prevState) => (prevState - 1))}>vorige</Button>
                            <Button onClick={() => setStep((prevState) => (prevState + 1))}>volgende</Button>
                        </Box>
                    </>
                }
                {step === 3 &&
                    <>
                        <BiestResult newSample={newSample} />
                        <br />

                        <Box display="flex" justifyContent="space-between" >
                            <Button onClick={() => setStep((prevState) => (prevState - 1))}>vorige</Button>
                            <Button onClick={sendSample}>
                                {newSample.id ? 'aanpassen' : 'opslaan'}
                            </Button>
                        </Box>
                    </>
                }
            </Container>
        </Dialog>
    )
}