import React, { useState } from "react"
import { Table, TableBody, TableRow, TableCell, Collapse, IconButton, Menu, MenuItem } from "@mui/material"
import { KeyboardArrowDown, KeyboardArrowUp, MoreVert as MoreVertIcon, } from "@mui/icons-material"

import deleteBiest from '../../../functions/deleteBiest'
import AddSampleDialog from "../biestComponents/addSampleComponents/AddSampleDialog"

export default function KoeInfoCell({ biestSample, setBiestList }) {
    const [open, setOpen] = useState(false)
    const [sample, setSample] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    function openMenu(event) {
        setAnchorEl(event.currentTarget)
    }

    function modifySample(biestSample){
        setSample(biestSample)
        setOpenAdd(true)
        setAnchorEl(null)
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell sx={{ fontSize:'12px', padding: 0 }}>
                    <IconButton size='small' onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp sx={{ fontSize:'15px'}}/> : <KeyboardArrowDown sx={{ fontSize:'15px'}}/>}
                    </IconButton>
                </TableCell>
                <TableCell sx={{fontSize:'12px'}} >{biestSample.kalfDatum}</TableCell>
                <TableCell sx={{fontSize:'12px'}} align='right'>{biestSample.biestBrixWaarde}</TableCell>
                <TableCell sx={{ fontSize:'12px', padding: 0 }}>
                    <IconButton size='small' onClick={openMenu}>
                        <MoreVertIcon sx={{ fontSize:'15px'}} />
                    </IconButton >
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ backgroundColor:'#EEEEEE', paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Table size='small'>
                            <TableBody >
                                <TableRow>
                                    <TableCell sx={{ width: '10px' }} />
                                    <TableCell sx={{fontSize:'12px'}}>koenummer</TableCell>
                                    <TableCell sx={{fontSize:'12px'}} align='right'>{biestSample.moederId}</TableCell>
                                    <TableCell sx={{ width: '10px' }} />
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '10px' }} />
                                    <TableCell sx={{fontSize:'12px'}} >leeftijd koe</TableCell>
                                    <TableCell sx={{fontSize:'12px'}} align='right'>{biestSample.moederLeeftijd}</TableCell>
                                    <TableCell sx={{ width: '10px' }} />
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '10px' }} />
                                    <TableCell sx={{fontSize:'12px'}} >kalfnummer</TableCell>
                                    <TableCell sx={{fontSize:'12px'}} align='right'>{biestSample.kalfId}</TableCell>
                                    <TableCell sx={{ width: '10px' }} />
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '10px' }} />
                                    <TableCell sx={{fontSize:'12px'}} >geslacht kalf</TableCell>
                                    <TableCell sx={{fontSize:'12px'}} align='right'>{biestSample.kalfGeslacht}</TableCell>
                                    <TableCell sx={{ width: '10px' }} />
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '10px' }} />
                                    <TableCell sx={{fontSize:'12px'}} >biest afname</TableCell>
                                    <TableCell sx={{fontSize:'12px'}} align='right'>{biestSample.biestTijdstip}</TableCell>
                                    <TableCell sx={{ width: '10px' }} />
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '10px' }} />
                                    <TableCell sx={{fontSize:'12px'}} >biest volume</TableCell>
                                    <TableCell sx={{fontSize:'12px'}} align='right'>{biestSample.biestVolume}</TableCell>
                                    <TableCell sx={{ width: '10px' }} />
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '10px' }} />
                                    <TableCell sx={{fontSize:'12px'}} >biest gedronken</TableCell>
                                    <TableCell sx={{fontSize:'12px'}} align='right'>{biestSample.biestGedronken}</TableCell>
                                    <TableCell sx={{ width: '10px' }} />
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '10px' }} />
                                    <TableCell sx={{fontSize:'12px'}} >biest IgG waarde</TableCell>
                                    <TableCell sx={{fontSize:'12px'}} align='right'>{biestSample.biestIgGWaarde}</TableCell>
                                    <TableCell sx={{ width: '10px' }} />
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
            <Menu
                id="cell-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem  sx={{fontSize:'12px'}} onClick={()=>deleteBiest(setBiestList, biestSample)}>Datapunt verwijderen</MenuItem>
                <MenuItem sx={{fontSize:'12px'}}  onClick={() => modifySample(biestSample)}>Datapunt aanpassen</MenuItem>
            </Menu>
            {sample && <AddSampleDialog open={openAdd} setOpen={setOpenAdd} setBiestList={setBiestList} sample={sample}/>}
        </React.Fragment>
    )
}