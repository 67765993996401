import React from "react"
import { Paper } from "@mui/material"

export default function KalfBackground() {
    return (
        <>
            <Paper
                sx={{
                    position: 'absolute',
                    top: 40,
                    left: 45,
                    maxWidth: 1.01,
                    maxHeight: window.innerHeight - 175 - 90,
                    background: "black",
                    borderRadius: '0px',
                    zIndex: 3000,

                }}
            />
            <Paper
                sx={{
                    position: 'absolute',
                    top: window.innerHeight - 175 - 50,
                    left: 45,
                    maxWidth: window.innerWidth - 65,
                    maxHeight: 1.01,
                    background: "black",
                    borderRadius: '0px',
                    zIndex: 3000,

                }}
            />
            <Paper
                sx={{
                    position: 'absolute',
                    top: 40,
                    left: 45 + 23 * (window.innerWidth - 65) / 45,
                    maxWidth: 2,
                    maxHeight: window.innerHeight - 175 - 90,
                    background: "#2196f3",
                    borderRadius: '0px',
                    zIndex: 3000,
                }}
            />
            <Paper
                sx={{
                    position: 'absolute',
                    top: 40 + 10 * (window.innerHeight - 265) / 16,
                    left: 45,
                    maxWidth: window.innerWidth - 65,
                    maxHeight: 2,
                    background: "#2196f3",
                    borderRadius: '0px',
                    zIndex: 3000,
                }}
            />
            <Paper
                elevation={0}
                sx={{
                    position: 'absolute',
                    top: 40,
                    left: 45,
                    maxWidth: 23 * (window.innerWidth - 65) / 45,
                    maxHeight: 10 * (window.innerHeight - 265) / 16,
                    background: "#F4433699",
                    borderRadius: '0px',
                    opacity: '40%',
                }}
            />
            <Paper
                elevation={0}
                sx={{
                    position: 'absolute',
                    top: 40,
                    left: 45 + 23 * (window.innerWidth - 65) / 45,
                    maxWidth: 22 * (window.innerWidth - 65) / 45,
                    maxHeight: 10 * (window.innerHeight - 265) / 16,
                    background: "#4caf50",
                    borderRadius: '0px',
                    opacity: '40%',
                }}
            />
            <Paper
                elevation={0}
                sx={{
                    position: 'absolute',
                    top: 40 + 10 * (window.innerHeight - 265) / 16,
                    left: 45,
                    maxWidth: 23 * (window.innerWidth - 65) / 45,
                    maxHeight: 6 * (window.innerHeight - 265) / 16,
                    background: "#F44336",
                    borderRadius: '0px',
                    opacity: '40%',
                }}
            />
            <Paper
                elevation={0}
                sx={{
                    position: 'absolute',
                    top: 40 + 10 * (window.innerHeight - 265) / 16,
                    left: 45 + 23 * (window.innerWidth - 65) / 45,
                    maxWidth: 22 * (window.innerWidth - 65) / 45,
                    maxHeight: 6 * (window.innerHeight - 265) / 16,
                    background: "#ff9800",
                    borderRadius: '0px',
                    opacity: '40%',
                }}
            />
        </>
    )
}