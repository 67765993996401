import React from "react"
import { Paper } from "@mui/material"

export default function KoeBackground() {
    return (
        <>
            <Paper
                sx={{
                    position: 'absolute',
                    top: 40,
                    left: 45,
                    maxWidth: window.innerWidth - 65,
                    maxHeight: Math.floor(2* (window.innerHeight - 175 - 90) / 5),
                    background: "#4caf50",
                    borderRadius: '0px',
                    opacity: '40%'
                }}
            />
            <Paper
                sx={{
                    position: 'absolute',
                    top: 40 + Math.ceil(2*(window.innerHeight - 175 - 90) / 5),
                    left: 45,
                    maxWidth: window.innerWidth - 65,
                    maxHeight: Math.floor((window.innerHeight - 175 - 90) / 5),
                    background: "linear-gradient(180deg, #4caf50 0%, #ff9800 50%, #F44336 100%)",
                    borderRadius: '0px',
                    opacity: '40%'
                }}
            />
            <Paper
                sx={{
                    position: 'absolute',
                    top: 40 + Math.ceil(3 * (window.innerHeight - 175 - 90) / 5),
                    left: 45,
                    maxWidth: window.innerWidth - 65,
                    maxHeight: Math.floor(2* ( window.innerHeight - 175 - 90) / 5),
                    background: "#F44336",
                    borderRadius: '0px',
                    opacity: '40%'
                }}
            />
            <Paper
                sx={{
                    position: 'absolute',
                    top: 40,
                    left: 45,
                    maxWidth: 1.01,
                    maxHeight: window.innerHeight - 175 - 90,
                    background: "black",
                    borderRadius: '0px',
                }}
            />
            <Paper
                sx={{
                    position: 'absolute',
                    top: window.innerHeight - 175 - 50,
                    left: 45,
                    maxWidth: window.innerWidth - 65,
                    maxHeight: 1.01,
                    background: "black",
                    borderRadius: '0px',
                }}
            />
        </>
    )
}