import React, {useState} from "react"
import { Box, Fab } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'

import AddBloodSampleDialog from "./addBloodSampleComponents/AddBloodSampleDialog"

export default function AddBloodSample({ biestList, setBiestList, selectedFarmer }) {
   
   const [open, setOpen] = useState(false)

    return (
        <>
            <Box display='flex' justifyContent='center' >
                <Fab
                    onClick={() => setOpen(true)}
                    sx={{ position: 'fixed', bottom: 150 }}
                    variant="extended"
                    size="large"
                    color="secondary"
                    aria-label="add"
                >
                    <AddIcon sx={{ mr: 1 }} />
                    Voeg bloedStaal toe
                </Fab>
            </Box>
            <AddBloodSampleDialog open={open} setOpen={setOpen} biestList={biestList} setBiestList={setBiestList} selectedFarmer={selectedFarmer} />
        </>
    )
}