import Papa from 'papaparse'

// Function to export data as a file
const exportCSV = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };


export default function exportData(biestList,email){
    let exportList = [[
        'koe nummer', 
        'koe leeftijd',
        'biest tijdstip',
        'biest volume [l]',
        'biest Brixwaarde [%]',
        'biest IgGwaarde [g/l]',
        'kalf nummer',
        'kalf geslacht',
        'kalf geboortedatum',
        'bloed Brixwaarde',
        'bloed IgGwaarde',
        'biest gedronken',
    ]]
    biestList.filter((biest) => biest.boerEmail === email)
    .map((userBiest) => exportList.push([
        userBiest.moederId,
        userBiest.moederLeeftijd,
        userBiest.biestTijdstip,
        userBiest.biestVolume,
        userBiest.biestBrixWaarde,
        userBiest.biestIgGWaarde,
        userBiest.kalfId,
        userBiest.kalfGeslacht,
        userBiest.kalfDatum,
        userBiest.bloedBrixWaarde,
        userBiest.bloedIgGWaarde,
        userBiest.biestGedronken,
    ]))
    const csvData = Papa.unparse(exportList);
    exportCSV(csvData, 'data.csv', 'text/csv;charset=utf-8;');
}


