export default function colorOfGradient(percentage) {

    const color_0 = [244, 67, 54]
    const color_100 = [0, 163, 94]
    const color_50 = [230, 233, 57]

    var rgb = []

    if (percentage < 29) {
        rgb = color_0
    } else if (percentage < 36) {
        rgb = [
            Math.round(color_50[0] * (percentage - 29) / 7 + color_0[0] * (1 - (percentage - 29) / 7)),
            Math.round(color_50[1] * (percentage - 29) / 7 + color_0[1] * (1 - (percentage - 29) / 7)),
            Math.round(color_50[2] * (percentage - 29) / 7 + color_0[2] * (1 - (percentage - 29) / 7)),
        ]
    } else if (percentage < 43) {
        rgb = [
            Math.round(color_100[0] * (percentage - 36) / 7 + color_50[0] * (1 - (percentage - 36) / 7)),
            Math.round(color_100[1] * (percentage - 36) / 7 + color_50[1] * (1 - (percentage - 36) / 7)),
            Math.round(color_100[2] * (percentage - 36) / 7 + color_50[2] * (1 - (percentage - 36) / 7)),
        ]
    } else {
        rgb = color_100
    }

    return (`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`)

}