import { API } from "aws-amplify"
import { deleteBiest as deleteBiestMutation } from "../graphql/mutations"
import fetchBiestList from "./fetchBiestList"

export default async function deleteBiest(setData, { id }) {
    await API.graphql({
        query: deleteBiestMutation,
        variables: { input: { id } },
    });
    fetchBiestList(setData)
}