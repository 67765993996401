import React from "react"
import { Amplify } from 'aws-amplify'

import AddSample from './biestComponents/AddSample'
import AddBloodSample from './biestComponents/AddBloodSample'
import OpenFridge from "./biestComponents/OpenFridge"

export default function Biest({ biestList, setBiestList, selectedFarmer }) {

    const admin = Amplify.Auth.user.attributes['custom:type'] === 'admin'

    return (
        <>           
            <AddSample setBiestList={setBiestList} />
            {admin && <AddBloodSample biestList={biestList} setBiestList={setBiestList} selectedFarmer={selectedFarmer} />}
            {/* <OpenFridge /> */}
        </>
    )
}