import React from "react"
import { Box, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from "dayjs"

export default function KalfInfo({ newSample, setNewSample }) {

    function handleChange(event) {
        event.target.value = event.target.value.replace(/,/g, '.')        
        setNewSample(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    let wrongKalfId = false
    let wrongVolume = false
    let wrongVolumeText = ''
    if (newSample.kalfId.includes(',')) { wrongKalfId = true }
    if (newSample.kalfId.includes('.')) { wrongKalfId = true }
    if (newSample.kalfId.includes('-')) { wrongKalfId = true }
    if (newSample.biestGedronken < 0) {
        wrongVolume = true
        wrongVolumeText = "onrealistisch melkvolume"
    }
    if(newSample.biestGedronken > 20){
        wrongVolume = true
        wrongVolumeText = "onrealistisch melkvolume"
    }
    if (newSample.biestGedronken*10%1 != 0) {
        wrongVolume = true
        wrongVolumeText = "rond af naar één cijfer na de komma"
    }

    return (
        <>
            {/* <TextField
                    error={wrongVolume}
                    id="biestSelect"
                    label="biest"
                    name="biestSelect"
                    variant="standard"
                    fullWidth
                    value={newSample.biestGedronken}
                    type="text"
                    inputProps={{ step: 0.1, inputMode: 'decimal', }}
                    margin="normal"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true, }}
                    helperText={wrongVolume && wrongVolumeText}
                />
            
            <br></br> */}
            
            <Box display="flex" justifyContent="space-between" >
                <TextField
                    error={wrongKalfId}
                    id="kalfId"
                    label="kalfnummer"
                    name="kalfId"
                    variant="standard"
                    value={newSample.kalfId}
                    size="normal"
                    margin="normal"
                    type="number"
                    sx={{ mr: '30px' }}
                    inputProps={{ inputMode: 'numeric', }}
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true, }}
                    helperText={wrongKalfId && "De ingevoerde waarde is niet geldig. Controleer of de waarde geen speciale tekens bevat, zoals een punt (.), een spatie (,) of een negatief teken (-)."}
                />

                <TextField
                    error={wrongVolume}
                    id="biestGedronken"
                    label="gedronken biest (L)"
                    name="biestGedronken"
                    variant="standard"
                    fullWidth
                    value={newSample.biestGedronken}
                    type="text"
                    inputProps={{ step: 0.1, inputMode: 'decimal', }}
                    margin="normal"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true, }}
                    helperText={wrongVolume && wrongVolumeText}
                />
            </Box>

            <br></br>

            <Box sx={{ mt: 1 }} display="flex" justifyContent="space-between" >
                <ToggleButtonGroup exclusive sx={{ mt: 2, height: '40px' }} color="primary" id='kalfGeslacht' name='kalfGeslacht' value={newSample.kalfGeslacht} onChange={handleChange}>
                    <ToggleButton size='small' name='kalfGeslacht' value='stier' >
                        aanhouden
                    </ToggleButton>
                    <ToggleButton size='small' name='kalfGeslacht' value='vaars'>
                        verkopen
                    </ToggleButton>
                </ToggleButtonGroup>

                <DatePicker
                    id='kalfDatum'
                    name='kalfDatum'
                    value={dayjs(newSample.kalfDatum)}
                    format='DD-MM-YYYY'
                    label='geboortedatum'
                    sx={{ marginLeft: '25px', flexGrow: 2, maxWidth: '100%' }}
                    onChange={(newValue) => setNewSample((prevState) => ({ ...prevState, kalfDatum: newValue }))}
                    slotProps={{
                        textField: {
                            variant: 'standard',
                        },
                    }}
                />


            </Box>
            <Typography sx={{ position: 'relative', color: 'gray', bottom: '63px', left: '0px' }} variant='caption'>beheer kalf</Typography>

        </>
    )
}