import React from "react"
import { Button } from "@mui/material"
import { PieChart as PieChartIcon, CandlestickChart as CandlestickChartIcon } from '@mui/icons-material';

export default function KalfPlotSelector({ pieSelect, setPieSelect }) {
    return (
        <Button
            size='small'
            startIcon={pieSelect ? <CandlestickChartIcon /> : <PieChartIcon />}
            variant="contained"
            sx={{ position: 'fixed', right: 10, bottom: 65, zIndex: 1000 }}
            id='pie-button'
            onClick={()=>setPieSelect((prevState) => !prevState)}
        >
            {pieSelect ? 'Toon grafiek' : 'Toon taartdiagram'}
        </Button >
    )
}