import React, { useState } from "react"
import { Amplify } from "aws-amplify"
import { Box, AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Select, FormControl, InputLabel } from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'

import settings from '../settings.js'
import exportData from '../functions/exportData'
import updateCompanyInfo from "../functions/updateCompanyInfo.js"
import eraseDataBase from "../functions/eraseDataBase"
import populateDatabase from "../functions/populateDataBase"

export default function Header({ signOut, biestList, setBiestList, selectedFarmer, setSelectedFarmer }) {

    const [openMenu, setOpenMenu] = useState(null);
    const admin = Amplify.Auth.user.attributes['custom:type'] === 'admin'
    const email = Amplify.Auth.user.attributes.email
    let farmers = Array.from(new Set(biestList.map((item) => item.boerEmail)))

    const handleChange = (event) => {
        setSelectedFarmer(event.target.value)
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ backgroundColor: settings.colors.header }}>
                <Toolbar display="flex" justify="space-between">
                    <Typography variant="button" sx={{ flexGrow: 1, color: settings.colors.headerItems }}>
                        CalfCheck
                    </Typography>
                    {admin &&
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Boer</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedFarmer}
                                    label="selectedFarmer"
                                    onChange={handleChange}
                                >
                                    {farmers.map((farmer) => (
                                        <MenuItem key={farmer} value={farmer}>{farmer}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    }
                    <IconButton sx={{ color: settings.colors.headerItems }} onClick={(event) => setOpenMenu(event.currentTarget)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={openMenu}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={Boolean(openMenu)}
                        onClose={() => setOpenMenu(null)}
                    >
                        <MenuItem onClick={function () { exportData(biestList, email); setOpenMenu(null) }}>Data exporteren</MenuItem>
                        {admin && <MenuItem onClick={function () { updateCompanyInfo(biestList, setBiestList); setOpenMenu(null) }}>Bedrijfsgegevens</MenuItem>}
                        {admin && <MenuItem onClick={function () { populateDatabase(setBiestList); setOpenMenu(null) }}>Creëer Database</MenuItem>}
                        {admin && <MenuItem onClick={function () { eraseDataBase(biestList, setBiestList); setOpenMenu(null) }}>Wis Database</MenuItem>}
                        <MenuItem onClick={signOut}>Uitloggen</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </Box >
    )
}