import React, { useState, useEffect } from "react"
import { Amplify } from 'aws-amplify'
import { Dialog, Container, Box, Button, Stepper, Step, StepLabel, Select, FormControl, InputLabel, MenuItem, TextField, Radio } from "@mui/material"
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from "dayjs"

import modifyBiest from '../../../../functions/modifyBiest'

export default function AddBloodSampleDialog({ open, setOpen, biestList, setBiestList, sample, selectedFarmer }) {

    const [selectedCalf, setSelectedCalf] = useState("")
    const [step, setStep] = useState(3)
    const [valueType, setValueType] = useState("IgG")

    const [bloedIgGWaarde, setBloedIgGWaarde] = useState("")
    const [bloedBrixWaarde, setBloedBrixWaarde] = useState("")
    const [bloedDatum, setBloedDatum] = useState(dayjs())

    let calfs = biestList.filter((biest) => biest.boerEmail === selectedFarmer).map((item) => item.kalfId).filter((calfid) => calfid !== "")

    const handleChange = (event) => {
        setSelectedCalf(event.target.value)
    };

    function handleBrixChange(event) {
        setBloedBrixWaarde(event.target.value)
    }

    function handleIgGChange(event) {
        setBloedIgGWaarde(event.target.value)
    }

    function handleOpslaan() {
        let sampleToModify = biestList.filter((item) => item.kalfId === selectedCalf)[0]
        if (valueType === "Brix") {

            sampleToModify = {
                ...sampleToModify,
                bloedBrixWaarde: Number(bloedBrixWaarde),
                bloedIgGWaarde: Math.round(Number(bloedBrixWaarde) * 9.1 - 59),
            }
        } else {
            sampleToModify = {
                ...sampleToModify,
                bloedIgGWaarde: Number(bloedIgGWaarde),
                bloedBrixWaarde: Math.round((Number(bloedIgGWaarde) + 59) / 9.1),
            }
        }
        modifyBiest(setBiestList, sampleToModify)
        setOpen(false)
    }

    return (
        <Dialog fullWidth open={open}>
            <Container sx={{ marginBottom: '10px' }}>

                <br />
                <Stepper activeStep={step} alternativeLabel>
                    <Step>
                        <StepLabel>koe</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>kalf</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>biest</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>bloed</StepLabel>
                    </Step>
                </Stepper>
                <br />
                <br />

                <Box display="flex" justifyContent="space-between" >
                    <Box sx={{ minWidth: 200 }}>
                        <FormControl fullWidth >
                            <InputLabel id="demo-simple-select-label">kalfnummer</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedCalf}
                                label="selectedCalf"
                                onChange={handleChange}
                            >
                                <MenuItem value=""> </MenuItem>
                                {calfs.map((calf) => (
                                    <MenuItem key={calf} value={calf}>{calf}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <DatePicker
                        id='staalDatum'
                        name='staalDatum'
                        value={dayjs(bloedDatum)}
                        format='DD-MM-YYYY'
                        label='datum staalname'
                        sx={{ marginLeft: '25px', flexGrow: 2, maxWidth: '100%' }}
                        onChange={(newValue) => setBloedDatum(newValue)}
                        slotProps={{
                            textField: {
                                variant: 'standard',
                            },
                        }}
                    />
                </Box>
                <br></br>
                <Box display="flex" justifyContent="space-between" >
                    <Radio
                        checked={valueType === "IgG"}
                        onChange={() => setValueType("IgG")}
                        value="IgG"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <TextField
                        // error={wrongBrix}
                        disabled={valueType === "Brix"}
                        id="bloedIgGWaarde"
                        label="IgG-waarde (in g/l)"
                        name="bloedIgGWaarde"
                        type="text"
                        variant="standard"
                        fullWidth
                        value={bloedIgGWaarde}
                        inputProps={{ step: 0.1, inputMode: 'decimal', }}
                        margin="normal"
                        onChange={handleIgGChange}
                        InputLabelProps={{ shrink: true, }}
                    // helperText={wrongBrix && wrongBrixText}
                    />
                    <Radio
                        checked={valueType === "Brix"}
                        onChange={() => setValueType("Brix")}
                        value="Brix"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }}
                    />
                    <TextField
                        // error={wrongBrix}
                        disabled={valueType === "IgG"}
                        id="bloedBrixWaarde"
                        label="Brix-waarde (in %)"
                        name="bloedBrixWaarde"
                        type="text"
                        variant="standard"
                        fullWidth
                        value={bloedBrixWaarde}
                        inputProps={{ step: 0.1, inputMode: 'decimal', }}
                        margin="normal"
                        onChange={handleBrixChange}
                        InputLabelProps={{ shrink: true, }}
                    // helperText={wrongBrix && wrongBrixText}
                    />
                    <br></br>
                    <br></br>

                </Box>
                <Box display="flex" justifyContent="space-between" >
                    <Button onClick={() => setOpen(false)}>annuleer</Button>
                    <Button onClick={handleOpslaan}>opslaan</Button>
                </Box>
            </Container >
        </Dialog >
    )
}