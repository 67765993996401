import React, { useState } from "react"
import { Box } from '@mui/material'
import dayjs from "dayjs"

import KoePlot from "./koeComponents/KoePlot"
import KoeInfo from "./koeComponents/KoeInfo"
import KoePeriod from "./koeComponents/KoePeriod"
import KoeBackground from "./koeComponents/KoeBackground"

export default function Koe({ biestList, setBiestList, selectedFarmer }) {

    const today = dayjs()
    const [period, setPeriod] = useState([today.subtract(7,'days').format('YYYY-MM-DD'), today.add(1,'day').format('YYYY-MM-DD')])
    const data = biestList.filter((biest) => biest.boerEmail === selectedFarmer)

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: '10px',
                        width: '100%',
                        height: window.innerHeight - 170,
                    },
                }}
            >
                <KoePlot data={data} xRange={period} />
                <KoeBackground />
            </Box>
            <KoeInfo data={data} setBiestList={setBiestList}/>
            <KoePeriod setPeriod={setPeriod}/>
        </>
    )
}