/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBiest = /* GraphQL */ `
  mutation CreateBiest(
    $input: CreateBiestInput!
    $condition: ModelBiestConditionInput
  ) {
    createBiest(input: $input, condition: $condition) {
      id
      boerNaam
      boerEmail
      bedrijfId
      boerderijNaam
      bedrijfAdres
      moederId
      moederLeeftijd
      kalfId
      kalfDatum
      kalfGeslacht
      biestTijdstip
      biestVolume
      biestGedronken
      biestBrixWaarde
      biestIgGWaarde
      biestGedronkenTijdstip
      bloedBrixWaarde
      bloedIgGWaarde
      toelichting
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBiest = /* GraphQL */ `
  mutation UpdateBiest(
    $input: UpdateBiestInput!
    $condition: ModelBiestConditionInput
  ) {
    updateBiest(input: $input, condition: $condition) {
      id
      boerNaam
      boerEmail
      bedrijfId
      boerderijNaam
      bedrijfAdres
      moederId
      moederLeeftijd
      kalfId
      kalfDatum
      kalfGeslacht
      biestTijdstip
      biestVolume
      biestGedronken
      biestBrixWaarde
      biestIgGWaarde
      biestGedronkenTijdstip
      bloedBrixWaarde
      bloedIgGWaarde
      toelichting
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBiest = /* GraphQL */ `
  mutation DeleteBiest(
    $input: DeleteBiestInput!
    $condition: ModelBiestConditionInput
  ) {
    deleteBiest(input: $input, condition: $condition) {
      id
      boerNaam
      boerEmail
      bedrijfId
      boerderijNaam
      bedrijfAdres
      moederId
      moederLeeftijd
      kalfId
      kalfDatum
      kalfGeslacht
      biestTijdstip
      biestVolume
      biestGedronken
      biestBrixWaarde
      biestIgGWaarde
      biestGedronkenTijdstip
      bloedBrixWaarde
      bloedIgGWaarde
      toelichting
      createdAt
      updatedAt
      __typename
    }
  }
`;
