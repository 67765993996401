import React, { useState } from "react"
import { Box } from '@mui/material'

import KalfBoxPlot from "./kalfComponents/KalfBoxPlot"
import KalfTaartDiagram from "./kalfComponents/KalfTaartDiagram"
import KalfInfo from "./kalfComponents/KalfInfo"
import KalfPlotSelector from "./kalfComponents/KalfPlotSelector"
import KalfBackground from "./kalfComponents/KalfBackground"

export default function Kalf({ biestList }) {

    const userList = biestList.filter((biest) => biest.boerNaam === 'boer_0')
    const [pieSelect, setPieSelect] = useState(false)

    let data = [2, 5, 2, 1]
    
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: '10px',
                        width: '100%',
                        height: window.innerHeight - 170,
                    },
                }}
            >
                {pieSelect ? <KalfTaartDiagram data={data} /> : <KalfBoxPlot data={userList} />}
                <KalfBackground />
            </Box>
            <KalfInfo userList={userList}/>
            <KalfPlotSelector pieSelect={pieSelect} setPieSelect={setPieSelect} />
        </>
    )
}